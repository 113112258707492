<div tabindex="0" class="eui-table__sticky-container" style="width: 100%" #scrollableContainerRef>
    <table #tableRef *ngIf="data" class="table-z-index-fix" aria-label="dg-table" euiTable euiTableBordered euiTableResponsive hasStickyHeader [rows]="data" [scrollableContainerRef]="scrollableContainerRef" (sortChange)="sortEvent.emit($event)">
        <ng-template euiTemplate="header">
            <tr *ngIf="shouldDisplaySortableHeaders">
                <th scope="col" *ngIf="merge && showMergeColumn"></th>
                <th scope="col" *ngFor="let header of columnsToDisplay" sortable [sortOn]="sortOn(header)" [id]="header"> {{'dg.grow.table.header.' + getHeader(header) | translate }}  </th>
                <th scope="col" *ngIf="shouldDisplayActions" [innerHTML]="shouldDisplayActionsHeader ? ('dg.grow.table.header.actions' | translate) : ''"></th>
            </tr>
            <tr *ngIf="!shouldDisplaySortableHeaders">
                <th scope="col" *ngFor="let header of columnsToDisplay" [id]="header"> {{'dg.grow.table.header.' + getHeader(header) | translate }}  </th>
            </tr>
            <tr class="eui-table__columns-filter" [formGroup]="filtersForm" *ngIf="showingFilters && filtersForm">
                <th scope="col" *ngIf="merge && showMergeColumn"></th>
                <th scope="col" *ngFor="let filter of filters" [ngSwitch]="filter.type">
                    <input [id]="filter.key+'-filter'" *ngSwitchCase="FilterType.TEXT" euiInputText [formControlName]="filter.key" (keyup.enter)="endEdit()">
                    <input [id]="filter.key+'-filter'" *ngSwitchCase="FilterType.NUMBER" euiInputNumber [formControlName]="filter.key" (keyup.enter)="endEdit()">
                    <input [id]="filter.key+'-filter'" *ngSwitchCase="FilterType.DECIMAL" euiInputNumber fillFraction [digits]="7" [fractionDigits]="3" [formControlName]="filter.key" (keyup.enter)="endEdit()">
                    <select [id]="filter.key+'-filter'" *ngSwitchCase="FilterType.LIST" euiSelect [formControlName]="filter.key" (change)="endEdit()">
                        <option *ngFor="let value of filter.options" [ngValue]="value.value">
                            <!-- Process Specific N/A value -->
                            <span *ngIf="value.label === 'N/A'; else default">{{'dg.grow.table.tooltip.ca.formD.na' | translate }}</span>
                            <ng-template #default>
                                {{value.label}}
                            </ng-template>
                        </option>
                    </select>
                </th>
                <th scope="col"></th>
            </tr>
        </ng-template>
        <ng-template let-row euiTemplate="body">
            <tr [ngClass]="{'selected': selectedRow?.[idField] === row[idField]}">
                <td *ngIf="merge && showMergeColumn" data-col-label="Merge">
                    <ng-container #mergeColumn [ngTemplateOutlet]="mergeColumnContent">
                    </ng-container>
                    <ng-template #mergeColumnContent>
                        <eui-icon-toggle iconSvgNameOff="eui-square-outline:eui" [attr.data-row-id]="row.id" #mergeToggle
                                         *ngIf="!(merge[0] && merge[1] && merge[0].id !== row.id && merge[1].id !== row.id)"
                                         [iconSvgNameOn]="((merge[0]  && merge[0].id !== row.id) || (merge[1] && merge[1].id === row.id) ) ? 'eui-close' : 'eui-chevron-forward:eui'"
                                         [isChecked]="(merge[0] && merge[0].id === row.id) || (merge[1] && merge[1].id === row.id)"
                                         (toggle)="emitMergeToggleClick(row, $event)">
                        </eui-icon-toggle>
                    </ng-template>
                </td>
                <td *ngFor="let header of columnsToDisplay" [headers]="header" class="table-row-data-{{header}}" [attr.data-col-label]="'dg.grow.table.header.' + getHeader(header) | translate">
                    <div [ngSwitch]="header">
                        <span *ngSwitchCase="AllCasesColumns.CASES">
                            <eui-chip euiSecondary>{{ row[header] | formatNumberToLocaleString }}</eui-chip>
                        </span>
                        <span *ngSwitchCase="LicitTradesTableColumns.IS_IMPORT">
                            <span *ngIf="row[header]; else export">
                                {{'dg.grow.form.d.page.part2.licit.trade.table.isImport.import.value' | translate}}
                            </span>
                            <ng-template #export>
                                {{'dg.grow.form.d.page.part2.licit.trade.table.isImport.export.value' | translate}}
                            </ng-template>

                        </span>
                        <span *ngSwitchCase="LicitTradesTableColumns.COUNTRY">
                            <span *ngIf="row[header]">
                                <span *ngIf="row[header].code" [ngClass]="'eui-flag-icon eui-flag-icon-'+ row[header].code.toLowerCase() + ' eui-u-mr-s'" ></span>{{row[header].name}}
                            </span>
                        </span>
                        <span *ngSwitchCase="AuthoritiesColumns.FORM_D_REPORTING">
                            <eui-icon-svg fillColor="success-100" icon="eui-ecl-check" size="m" euiTooltip="{{'dg.grow.table.tooltip.ca.formDSubmit.allowed' | translate}}" *ngIf="row[header];else f"></eui-icon-svg>
                            <ng-template #f>
                                <eui-icon-svg fillColor="grey-35" icon="ban" size="s" set="outline" euiTooltip="{{'dg.grow.table.tooltip.ca.formDSubmit.not.allowed' | translate}}"></eui-icon-svg>
                            </ng-template>
                        </span>
                        <span *ngSwitchCase="AuthoritiesColumns.Q1">
                            <eui-icon-svg fillColor="success-100" icon="eui-ecl-check" size="m" euiTooltip="{{'dg.grow.generic.complete' | translate}}" *ngIf="row[header]===completed;else f1"></eui-icon-svg>
                            <ng-template #f1>
                                <eui-icon-svg class="eui-u-ml-2xs" fillColor="warning-100" icon="eui-file-signature" size="m" euiTooltip="{{'dg.grow.generic.not.completed' | translate}}" *ngIf="row[header]===notCompleted;else f2"></eui-icon-svg>
                                <ng-template #f2>
                                    <eui-icon-svg fillColor="grey-35" icon="eui-file-empty-o" euiTooltip="{{'dg.grow.table.tooltip.ca.q.na' | translate}}" ></eui-icon-svg>
                                </ng-template>
                            </ng-template>
                        </span>
                        <span *ngSwitchCase="AuthoritiesColumns.Q2">
                            <eui-icon-svg fillColor="success-100" icon="eui-ecl-check" size="m" euiTooltip="{{'dg.grow.generic.complete' | translate}}" *ngIf="row[header]===completed;else f1"></eui-icon-svg>
                            <ng-template #f1>
                                <eui-icon-svg class="eui-u-ml-2xs" fillColor="warning-100" icon="eui-file-signature" size="m" euiTooltip="{{'dg.grow.generic.not.completed' | translate}}" *ngIf="row[header]===notCompleted;else f2"></eui-icon-svg>
                                <ng-template #f2>
                                    <eui-icon-svg fillColor="grey-35" icon="eui-file-empty-o" euiTooltip="{{'dg.grow.table.tooltip.ca.q.na' | translate}}" ></eui-icon-svg>
                                </ng-template>
                            </ng-template>
                        </span>
                        <span *ngSwitchCase="AuthoritiesColumns.Q3">
                            <eui-icon-svg fillColor="success-100" icon="eui-ecl-check" size="m" euiTooltip="{{'dg.grow.generic.complete' | translate}}" *ngIf="row[header]===completed;else f1"></eui-icon-svg>
                            <ng-template #f1>
                                <eui-icon-svg class="eui-u-ml-2xs" fillColor="warning-100" icon="eui-file-signature" size="m" euiTooltip="{{'dg.grow.generic.not.completed' | translate}}" *ngIf="row[header]===notCompleted;else f2"></eui-icon-svg>
                                <ng-template #f2>
                                    <eui-icon-svg fillColor="grey-35" icon="eui-file-empty-o" euiTooltip="{{'dg.grow.table.tooltip.ca.q.na' | translate}}"></eui-icon-svg>
                                </ng-template>
                            </ng-template>
                        </span>
                        <span *ngSwitchCase="AuthoritiesColumns.Q4">
                            <eui-icon-svg fillColor="success-100" icon="eui-ecl-check" size="m" euiTooltip="{{'dg.grow.generic.complete' | translate}}" *ngIf="row[header]===completed;else f1"></eui-icon-svg>
                            <ng-template #f1>
                                <eui-icon-svg class="eui-u-ml-2xs" fillColor="warning-100" icon="eui-file-signature" size="m" euiTooltip="{{'dg.grow.generic.not.completed' | translate}}" *ngIf="row[header]===notCompleted;else f2"></eui-icon-svg>
                                <ng-template #f2>
                                    <eui-icon-svg fillColor="grey-35" icon="eui-file-empty-o " euiTooltip="{{'dg.grow.table.tooltip.ca.q.na' | translate}}"></eui-icon-svg>
                                </ng-template>
                            </ng-template>
                        </span>
                        <span *ngSwitchCase="AuthoritiesColumns.FORM_D">
                            <eui-icon-svg fillColor="success-100" icon="eui-ecl-check" size="m" euiTooltip="{{'dg.grow.generic.complete' | translate}}" *ngIf="row[header]===completed;else f1"></eui-icon-svg>
                            <ng-template #f1>
                                <eui-icon-svg class="eui-u-ml-2xs" fillColor="warning-100" icon="eui-file-signature" euiTooltip="{{'dg.grow.generic.not.completed' | translate}}" size="m" *ngIf="row[header]===notCompleted;else f2"></eui-icon-svg>
                                <ng-template #f2>
                                    <eui-icon-svg fillColor="grey-35" icon="eui-file-empty-o " euiTooltip="{{'dg.grow.table.tooltip.ca.formD.na' | translate}}"></eui-icon-svg>
                                    <eui-icon fillColor="success-100" iconClass="eui-ecl-check" size="m" *ngIf="row[header];else f"></eui-icon>
                                        <ng-template #f>
                                            <eui-icon-svg fillColor="warning-100" icon="remove-circle" set="outline"></eui-icon-svg>
                                        </ng-template>
                                </ng-template>
                            </ng-template>
                        </span>
                        <span *ngSwitchCase="AuthoritiesColumns.STATUS">
                            <eui-icon-svg fillColor="success-100" icon="checkmark-circle" size="s"  set="sharp" euiTooltip="{{'dg.grow.generic.active' | translate}}" *ngIf="row[header];else f"></eui-icon-svg>
                            <ng-template #f>
                                <eui-icon-svg fillColor="grey-35" icon="close-circle" size="s" set="sharp" euiTooltip="{{'dg.grow.generic.inactive' | translate}}"></eui-icon-svg>
                            </ng-template>
                        </span>
                        <span *ngSwitchCase="'deletedOperator'">
                            <eui-icon-svg fillColor="success-100" icon="eui-ecl-check" size="m" *ngIf="row[header];else f"></eui-icon-svg>
                            <ng-template #f>
                                <eui-icon-svg fillColor="grey-35" icon="ban" size="s" set="outline"></eui-icon-svg>
                            </ng-template>
                        </span>
                        <span *ngSwitchCase="AuthoritiesColumns.OPERATORS">
                            <eui-icon-svg fillColor="success-100" icon="eui-ecl-check" size="m" euiTooltip="{{'dg.grow.table.tooltip.ca.operators.true' | translate}}" *ngIf="row[header];else f"></eui-icon-svg>
                            <ng-template #f>
                                <eui-icon-svg fillColor="grey-35" icon="ban" size="s" set="outline" euiTooltip="{{'dg.grow.table.tooltip.ca.operators.false' | translate}}"></eui-icon-svg>
                            </ng-template>
                        </span>
                        <span *ngSwitchCase="'htmlContent'">
                            <span [innerHTML]="row[header]"></span>
                        </span>
                        <span *ngSwitchCase="'linkContent'">
                            <!-- {{row[header] | json}} -->
                            <ng-container *ngIf="row[header] && row[header].url">
                                <a (click)="getFileLink(row[header].url)">{{row[header]['text']}}</a>
                            </ng-container>
                        </span>
                        <span *ngSwitchCase="LicitTradesTableColumns.SUBSTANCE_NAME" [ngClass]="{'eui-u-color-danger': row[invalidSubstance]}" [euiTooltip]="row[invalidSubstance]? invalidSubstanceTooltip : ''" [position]="'left'">
                           {{row[header]}}
                        </span>
                        <span *ngSwitchDefault>
                            <span *ngIf="columnTypeMap.get(header); else nopipe">
                                {{ row[header] | presentNegativeNumberAsDash | formatNumberToLocaleString: getScale(header, row) }}
                            </span>
                            <ng-template #nopipe>
                                {{row[header]}}
                            </ng-template>
                        </span>
                    </div>
                </td>
                <td data-col-label="Actions"  *ngIf="shouldDisplayActions">
                    <eui-dropdown position="bottom">
                        <button euiButton euiBasicButton euiIconButton euiRounded euiPrimary euiSizeS [euiTooltip]="actionTooltipText" [position]="'left'">
                            <eui-icon-svg icon="eui-ellipsis-vertical"></eui-icon-svg>
                        </button>
                        <eui-dropdown-content>
                            <ul euiList>
                                <li *ngIf="showView" euiListItem [euiTooltip]="viewTooltipText" [position]="'left'" (click)="onViewRow(row)">
                                    <eui-icon-svg euiInfo icon="eye" set="sharp" fillColor="info-100"></eui-icon-svg>
                                    <span *ngIf="!formDPart1Table" euiLabel euiPrimary>{{'dg.grow.table.actions.view.label' | translate}}</span>
                                    <span *ngIf="formDPart1Table" euiLabel euiPrimary>{{'dg.grow.table.actions.view.comment.label' | translate}}</span>
                                </li>
                                <li *ngIf="showEdit && (!formDPart1Table || (formDPart1Table && !isComplete)) &&
                                            (!canEditSpecificResource ||
                                                (canEditSpecificResource &&
                                                    (
                                                        row[canEditSpecificResourceKey]
                                                    )
                                                )
                                            )" euiListItem [euiTooltip]="editTooltipText" [position]="'left'" (click)="onEditRow(row)">
                                    <eui-icon-svg euiInfo icon="eui-pencil" fillColor="info-100"></eui-icon-svg>
                                    <span *ngIf="!formDPart1Table" euiLabel euiPrimary>{{'dg.grow.table.actions.edit.label' | translate}}</span>
                                    <span *ngIf="formDPart1Table" euiLabel euiPrimary>{{'dg.grow.table.actions.edit.comment.label' | translate}}</span>
                                </li>
                                <li *ngIf="showDelete && (!formDPart1Table || (formDPart1Table && !isComplete)) &&
                                            (!canDeleteSpecificResource ||
                                                (canDeleteSpecificResource &&
                                                    (
                                                        row[canDeleteSpecificResourceKey]
                                                    )
                                                )
                                            )" euiListItem [euiTooltip]="deleteTooltipText" [position]="'left'" (click)="onDeleteRow(row)">
                                    <eui-icon-svg euiInfo icon="eui-delete" fillColor="info-100"></eui-icon-svg>
                                    <span *ngIf="!formDPart1Table" euiLabel euiPrimary>{{'dg.grow.table.actions.delete.label' | translate}}</span>
                                    <span *ngIf="formDPart1Table" euiLabel euiPrimary>{{'dg.grow.table.actions.delete.comment.label' | translate}}</span>
                                </li>
                            </ul>
                        </eui-dropdown-content>
                    </eui-dropdown>
                </td>
            </tr>
        </ng-template>
        <ng-template euiTemplate="footer" *ngIf="showTotals">
            <tr *ngIf="totals" class="sticky-footer">
                <td [attr.colspan]="totalsColspanOffset"><strong>{{'dg.grow.table.total.text' | translate}}</strong></td>
                <td><strong>{{ totals.totalCases | formatNumberToLocaleString }}</strong></td>
                <td><strong>{{ totals.totalKilograms | formatNumberToLocaleString: 5 }}</strong></td>
                <td><strong>{{ totals.totalLitres | formatNumberToLocaleString: 5 }}</strong></td>
                <td><strong>{{ totals.totalGrams | formatNumberToLocaleString: 3 }}</strong></td>
                <td><strong>{{ totals.totalMillilitres | formatNumberToLocaleString: 3 }}</strong></td>
                <td colspan="100"></td>
            </tr>
        </ng-template>
        <ng-template euiTemplate="emptyMessage">
            <tr>
                <td class="eui-u-text-center" colspan="15">{{'dg.grow.table.no.data.text' | translate}}</td>
            </tr>
        </ng-template>
    </table>
</div>
<eui-paginator [isHidden]="!hasPaginator" #paginator [pageSizeOptions]="[10, 20, 50]" [pageSize]="10" [length]="totalResults" hasPageNumberNavigation [nbPageNumberNavigation]="3" (pageChange)="paginationEvent.emit($event)"></eui-paginator>
