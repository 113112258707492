<eui-block-content *ngIf="!applicationLoaded" [isBlocked]="!applicationLoaded">
    <div class="app-loading"></div>
</eui-block-content>
<eui-app [userInfos]="userInfos?.name" [userSubInfos]="userInfos?.userRolesNames | join:', '" isSidebarOpen=true *ngIf="applicationLoaded" [ngClass]="{'anonymous': !isAuthenticated}">
    <eui-app-header>
        <eui-header>
            <eui-header-logo></eui-header-logo>
            <eui-header-environment *ngIf="SHOW_HEADER_ENVIRONMENT">{{HEADER_ENVIRONMENT}}</eui-header-environment>
            <eui-header-app [appName]="'dg.grow.app.header.app.name' | translate" [appSubTitle]="'dg.grow.app.header.app.subtitle' | translate">
            </eui-header-app>
            <eui-header-user-profile *ngIf="isAuthenticated" [hasWelcomeLabel]="true">
                <eui-user-profile-menu>
                    <eui-user-profile-menu-item style="flex-wrap: wrap">
                        <div style="width: 100%">{{userInfos?.name}}</div>
                        <br>
                        <ng-container *ngIf="userInfos?.userRolesNames">
                            <div style="width: 100%" *ngFor="let role of userInfos?.userRolesNames">{{role}}</div><br>
                        </ng-container>
                    </eui-user-profile-menu-item>
                    <eui-user-profile-menu-item (click)="logout($event)">
                        <eui-icon-svg icon="eui-icon-logout-thin"></eui-icon-svg>
                        {{ 'dg.grow.toolbar.profile.menu.item.logout' | translate }}
                    </eui-user-profile-menu-item>
                </eui-user-profile-menu>
            </eui-header-user-profile>
        </eui-header>
    </eui-app-header>

    <eui-app-toolbar class="eui-u-z-index-btn-floating">
        <eui-toolbar>
            <eui-toolbar-logo></eui-toolbar-logo>
            <eui-toolbar-menu *ngIf="isAuthenticated" [items]="menuItems"></eui-toolbar-menu>
            <eui-toolbar-items *ngIf="isAuthenticated" euiPositionRight>
                <!-- Show user profile inside toolbar only on small xs-sm with custom css class -->
                <div class="hidden-toolbar-user-profile">
                    <eui-toolbar-item-user-profile>
                        <eui-user-profile-menu>
                            <eui-user-profile-menu-item style="flex-wrap: wrap">
                                <div style="width: 100%">{{userInfos?.name}}</div>
                                <br>
                                <ng-container *ngIf="userInfos?.userRolesNames">
                                    <div style="width: 100%" *ngFor="let role of userInfos?.userRolesNames">{{role}}</div><br>
                                </ng-container>
                            </eui-user-profile-menu-item>
                            <eui-user-profile-menu-item (click)="logout($event)">
                                <eui-icon-svg icon="eui-icon-logout-thin"></eui-icon-svg>
                                {{ 'dg.grow.toolbar.profile.menu.item.logout' | translate }}
                            </eui-user-profile-menu-item>
                        </eui-user-profile-menu>
                    </eui-toolbar-item-user-profile>
                </div>
                <eui-toolbar-item>
                    <eui-dropdown>
                        <eui-icon-svg icon="eui-question-circle" class="help-menu-icon" aria-label="Help" fillColor="white" ></eui-icon-svg>
                        <eui-dropdown-content>
                            <div euiList *ngIf="helpItems?.length">
                                <button *ngFor="let helpItem of helpItems;" euiListItem (click)="userGuideClicked(UserGuideEnum[helpItem.id])">
                                    {{ helpItem.label }}
                                </button>
                            </div>
                        </eui-dropdown-content>
                    </eui-dropdown>
                </eui-toolbar-item>
            </eui-toolbar-items>
        </eui-toolbar>
    </eui-app-toolbar>

    <eui-app-page-wrapper *ngIf="!(userInfos?.username === AUTH_CONSTANT_INVALID) && (userInfos?.privacyStatementAccepted === null || userInfos?.privacyStatementAccepted === false)"></eui-app-page-wrapper>

    <eui-app-footer>
        <eui-footer>
            <div>
                <a *ngIf="isAuthenticated" (click)="privacyStatementClicked()" class="eui-u-pr-xl">{{ 'dg.grow.footer.label.1' | translate}}</a>
                <a [href]="LINKS.FOOTER_CONTACT_US" target="_blank" rel="noopener">{{ 'dg.grow.footer.label.2' | translate}}</a>
            </div>
        </eui-footer>
    </eui-app-footer>

    <eui-dialog #privacyStatementDialog
        title="{{'dg.grow.privacy.statement.dialog.title' | translate}}"
        [isClosedOnEscape]="!isWaitingPrivacyStatementDialogAction && userInfos.privacyStatementAccepted === true" [hasCloseButton]="!isWaitingPrivacyStatementDialogAction && userInfos.privacyStatementAccepted === true">
            <privacy-statement-dialog-body></privacy-statement-dialog-body>
        <eui-dialog-footer>
            <div class="eui-u-flex ">
                <button euiButton [disabled]="isWaitingPrivacyStatementDialogAction" class="eui-u-mr-m" (click)="privacyStatementPrintClicked($event)">
                    <span class="eui-print" ></span>
                    <span euiLabel>{{'dg.grow.privacy.statement.dialog.button.print' | translate}}</span>
                </button>
                <div style="flex: 1"></div>
                <button *ngIf="userInfos.privacyStatementAccepted === null || userInfos.privacyStatementAccepted === false" euiButton [disabled]="isWaitingPrivacyStatementDialogAction" class="eui-u-mr-m" (click)="privacyStatementDeclineClicked($event)" [disabled]="false">
                    <span euiLabel>{{'dg.grow.privacy.statement.dialog.button.decline' | translate}}</span>
                </button>
                <button *ngIf="userInfos.privacyStatementAccepted === null || userInfos.privacyStatementAccepted === false" euiButton euiPrimary [disabled]="isWaitingPrivacyStatementDialogAction" (click)="privacyStatementAcceptClicked($event)" [disabled]="false">
                    <span euiLabel>{{'dg.grow.privacy.statement.dialog.button.accept' | translate}}</span>
                </button>
            </div>
        </eui-dialog-footer>
    </eui-dialog>

</eui-app>
